import React, { useState } from "react";
import RingOssKnapp from "./components/RingOssKnapp.js";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import logo from "./assets/images/loggo/IstraLogo.png";

import nyhet from "./assets/images/nyheter/snacksbox.png";
import Meny from "./pages/Meny.js";
import OmOss from "./pages/Omoss.js";
import SocialMediaIcons from "./components/SocialMediaIcons";

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const LogoLink = () => {
    const navigate = useNavigate();
    return (
      <img
        src={logo}
        alt="Pizzeria Logga"
        className="pizzeria-logga1"
        onClick={() => {
          navigate("/");
          closeMenu();
        }}
        style={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <Router>
      <div className="pizzeria-app">
        <video
          src="https://public-assets-istra.s3.eu-central-1.amazonaws.com/homePageBg.mp4"
          type="video/mp4"
          autoPlay
          loop
          muted
          playsInline
          className="home-page-bg"
        />
        <div
          className={`burger-menu ${isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
          <div className="burger-bar"></div>
        </div>
        <div className="pizzeria-logga-container">
          <LogoLink />
        </div>
        <div className="huvudmeny-container">
          <nav className={`huvudmeny ${isMenuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <Link to="/meny" onClick={closeMenu}>
                  MENY
                </Link>
              </li>
              <li>
                <Link to="/" onClick={closeMenu}>
                  <img
                    src={logo}
                    alt="Pizzeria Logga"
                    className="pizzeria-logga"
                  />
                </Link>
              </li>
              <li>
                <Link to="/om-oss" onClick={closeMenu}>
                  OM OSS
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <RingOssKnapp phoneNumber="042-28 27 94" />
        <Routes>
          <Route path="/meny" element={<Meny />} />
          <Route path="/om-oss" element={<OmOss />} />
          <Route
            path="/"
            element={
              <header
                className={`välkomstsida ${isMenuOpen ? "menu-open" : ""}`}
              >
                <div className="välkomstsida-content">
                  <h2>Välkommen till</h2>
                  <h1>Pizzeria Istra</h1>
                  <p>Helsingborg</p>
                  <p>
                    Njut av våra utsökta pizzor tillagade med <br /> kärlek och
                    de finaste ingredienserna.
                  </p>
                  <div className="lunch-nyheter-container">
                    <div className="nyheter-banner">
                      <div className="nyhet-container">
                        <div className="nyhet-bild-container">
                          <img src={nyhet} alt="Nyhet" className="nyhet-bild" />
                        </div>
                        <div className="nyhet-text">
                          <h2>Nyhet! 89kr</h2>
                          <p>
                            Snackbox fylld med Mozzarellasticks, Lökringar,
                            Pommes, Chicken wings eller Chicken nuggets
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="lunch-info">
                      <div className="lunch-text">
                        <h2>Lunch</h2>
                        <p>
                          Serveras mån-fre Kl 11:00-14:00
                          <br />
                          Gäller alla maträtter
                          <br />
                          Dricka ingår
                        </p>
                      </div>

                      <div className="kontaktuppgifter-välkomstsida">
                        <h3>Kontakta oss</h3>
                        <p>
                          Ring oss:
                          <br />
                          <a href="tel:042-28 27 94">042-28 27 94</a>/
                          <a href="tel:070-894 33 22">070-894 33 22</a>
                        </p>
                        <p>
                          Adress:{" "}
                          <a
                            href="https://www.google.com/maps/dir/?api=1&destination=Elinebergsvägen+14,+256+59+Helsingborg"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Elinebergsvägen 14, 256 59 Helsingborg
                          </a>
                        </p>
                        <p>
                          Dommaregatan, vid Ramlösarondellen/ Elinebergskiosken
                        </p>
                        <p>Öppettider: Mån-Sön 10-22</p>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
            }
          />
        </Routes>
        <SocialMediaIcons />
      </div>
    </Router>
  );
}

export default App;
